import '@clickbar/tailwindui-vue/style.css'
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto-condensed/300.css'
import '@fontsource/roboto-condensed/400.css'
import '@fontsource/roboto-condensed/700.css'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { trail } from 'momentum-trail'
import { createApp, h } from 'vue'

import '../css/app.css'

import type { DefineComponent } from 'vue'

const appName = 'Klimastation Frankfurt'

createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  progress: {
    color: '#00557f',
  },
  resolve: async (name: string) => {
    return await resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob<DefineComponent>('./Pages/**/*.vue')
    )
  },
  setup({ el, App, props, plugin }) {
    const appInstance = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(trail, { routes: null })
      .component('inertia-link', Link)

    appInstance.mount(el)
  },
})
